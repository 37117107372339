<template>
	<div class="friendCirclEdit page">
		<div class="s1 p-card fs-16 m-3">
			<div class="flex h-between p-3 pointer"
			     @click="handleChose(0)">
				<div>发布内容</div>
				<div class="flex">
					<div class="fs-16 b mr-1">{{ dialog_pick }}</div>
					<div class="p-icon-arrow"/>
				</div>
			</div>
		</div>
		<div class="s1 p-card fs-16 m-3">
			<div class="flex h-between p-3 pointer"
			     @click="handleChose(1)">
				<div>推送方式</div>
				<div class="flex">
					<div class="fs-16 b mr-1">{{ dialog1_pick }}</div>
					<div class="p-icon-arrow"/>
				</div>
			</div>
			<div v-if="fromData.pushType"
			     class="flex h-between p-3 pointer"
			     @click="handleChose(2)">
				<div>推送时间</div>
				<div class="flex">
					<div class="fs-16 b mr-1">{{ fromData.pushDateTime }}</div>
					<div class="p-icon-arrow"/>
				</div>
			</div>
		</div>

		<div class="s1 m-3">
			<div class="wrap">
				<div class="textarea px-3">
          <textarea v-model="fromData.title"
                    maxlength="300"
                    placeholder="请输入"/>
					<p>{{ fromData.title.length }}/300</p>
				</div>
				<van-grid v-if="fromData.releaseType === 2002"
				          :column-num="3"
				          :border="false">
					<van-grid-item v-for="(src, index) in fromData.imgs"
					               :key='index'>
						<van-image :src="src"
						           fit="scale-down"/>
						<van-icon @click="removeImgItem(src)"
						          name="clear"/>
					</van-grid-item>
					<van-grid-item v-if="fromData.imgs.length < 9">
						<div class="addImg"
						     @click="inputClick">
							<van-icon name="plus"/>
							<input ref="imgInput"
							       class="file"
							       type="file"
							       @change="addImgList">
						</div>
					</van-grid-item>
				</van-grid>
				<div v-if="fromData.releaseType === 2005">
					<div class="flex fs-16 px-3">
						<div>标题</div>
						<div class="flex-1">
							<input v-model="fromData.linkTitle"
							       type="text"
							       placeholder="请输入链接标题"
							       class="inp"
							       maxlength="10">
						</div>
					</div>
					<div class="divider ml-3"/>
					<div class="flex fs-16 p-3">
						<div class="flex-1">封面</div>
						<div>
							<div v-if="fromData.linkImg"
							     class="avatar">
								<img :src="fromData.linkImg"
								     mode="aspectFill"
								     class="img">
								<div class="del"
								     @click="fromData.linkImg=''"/>
							</div>
							<div v-else
							     class="avatar add"><input class="file"
							                               type="file"
							                               @change="uploadImg"></div>
						</div>
					</div>
					<div class="divider ml-3"/>
					<div class="flex fs-16 px-3">
						<div>地址</div>
						<div class="flex-1">
							<input v-model="fromData.linkUrl"
							       type="text"
							       placeholder="请输入链接的http地址"
							       class="inp">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="p-footer h-end">
			<div class="flex  flex-right">
				<button class="btn auto"
				        @click="$router.go(-1)">取消
				</button>
				<button class="btn button auto fill ml-3" :disabled="saveLoading"
				        @click="submit">保存
				</button>
			</div>
		</div>
		<van-action-sheet v-bind:show="dialog"
		                  :actions="actions"
		                  @select="dialogHide"
		                  cancel-text="取消"
		                  close-on-click-action
		                  @cancel="dialog = false"/>
		<van-action-sheet v-bind:show="dialog1"
		                  :actions="actions1"
		                  @select="dialogHide1"
		                  cancel-text="取消"
		                  close-on-click-action
		                  @cancel="dialog1 = false"/>
		<van-popup v-model:show="dialog2"
		           position="bottom"
		           :style="{ height: '50%' }">
			<van-datetime-picker @cancel="dialog2 = false"
			                     :min-date="minDate"
			                     @confirm="setTime"
			                     type="datetime"
			                     title="选择完整时间"/>
		</van-popup>
	</div>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import {
	mapGetters
} from 'vuex'
import {
	dateFormat
} from '@/utils'
import {
	ossUploadFile
} from '@/utils/index'

function initFromData() {
	return {
		ucId: 0,
		pushType: 0, // 0立即推送、1定时推送
		pushDateTime: '',
		releaseType: 2001, // 类型 2001文字 2002图文 2004视频 2005链接
		title: '',
		imgs: [],
		linkTitle: '',
		linkImg: '',
		linkUrl: ''
	}
}

export default {
	name: 'KeyWords',
	components: {
		msgBox
	},
	data() {
		return {
			saveLoading: false,
			dialog: false,
			dialog_pick: '纯文字',
			actions: [{
				name: '纯文字',
				value: 2001
			},
				{
					name: '文字+图片',
					value: 2002
				},
				{
					name: '文字+链接',
					value: 2005
				}
			],
			dialog1: false,
			dialog1_pick: '立即推送',
			actions1: [{
				name: '立即推送',
				value: 0
			},
				{
					name: '定时推送',
					value: 1
				}
			],
			dialog2: false,
			fromData: initFromData(),
			minDate: new Date((new Date()).getTime() + 60 * 1000)
		}
	},
	computed: {
		...mapGetters(['contentData', 'globalData', 'robotInfo'])
	},
	created() {
		this.initData()
	},
	methods: {
		initData() {
			this.getData()
		},
		getData() {
			this.$get('/UserFriendCircle/GetUserFriendCircle', {
				    id: this.$route.query.isEdit
			    })
			    .then(res => {
				    if (res) {
					    this.fromData = JSON.parse(JSON.stringify(res))
					    this.dialog_pick = this.actions.find(obj => obj.value == this.fromData.releaseType).name
					    this.dialog1_pick = this.fromData.pushType ? '定时推送' : '立即推送'
				    }
			    })
		},
		uploadImg(e) {
			if (e.target.files.length) {
				const file = e.target.files[0]
				const isImage = file.type.indexOf('image') > -1
				if (!isImage) {
					this.$Toast('请选择正确的图片格式！')
					return
				}
				ossUploadFile(file)
						.then(res => {
							if (res) {
								this.fromData.linkImg = res
							}
						})
			}
		},
		handleChose(val) {
			if (!val) {
				this.dialog = true
			} else if (val === 1) {
				this.dialog1 = true
			} else {
				this.dialog2 = true
			}
		},
		dialogHide(e) {
			this.dialog_pick = e.name
			this.fromData.releaseType = e.value
			this.dialog = false
		},
		dialogHide1(e) {
			this.dialog1_pick = e.name
			this.fromData.pushType = e.value
			this.dialog1 = false
		},
		setTime(e) {
			console.log(dateFormat(e, 'yyyy-MM-dd hh:mm'))
			this.fromData.pushDateTime = dateFormat(e, 'yyyy-MM-dd hh:mm')
			this.dialog2 = false
		},
		inputClick() {
			this.$refs['imgInput'].click()
			console.log(this.$refs['imgInput'].value = '')
		},
		addImgList(e) {
			if (e.target.files.length) {
				const file = e.target.files[0]
				const isImage = file.type.indexOf('image') > -1
				if (!isImage) {
					this.$Toast('请选择正确的图片格式！')
					return
				}
				ossUploadFile(file)
						.then(res => {
							if (res) {
								this.fromData.imgs.push(res)
							}
						})
			}
		},
		removeImgItem(src) {
			console.log(src)
			this.fromData.imgs = this.fromData.imgs.filter(obj => obj != src)
		},
		async submit(n) {
			this.saveLoading = true
			await this.$post('/UserFriendCircle/UserFriendCircleSave', this.fromData)
			          .then((res) => {
				          this.$Toast('保存成功')
				          this.$router.go(-1)

			          })
			          .catch(e => {
				          console.log(e)
				          this.saveLoading = false
			          })
		}
	}
}
</script>

<style scoped lang="scss">
.page {
	padding-bottom: px(50);

	.s2 {
		.item {
			margin-left: px(15);
			padding: px(15) px(15) px(15) 0;
			border-bottom: px(1) #f1f1f1 dashed;
			font-size: px(14);

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.s1 {
		.wrap {
			position: relative;
			background: #ffffff;
			box-shadow: 0px px(1) px(1) 0px rgba(0, 0, 0, 0.06);
			border-radius: px(4);
			color: #666;
			padding: px(15) 0;

			.textarea {
				textarea {
					width: 100%;
					height: px(180);
					line-height: px(24);
					box-sizing: border-box;
					font-size: px(16);
					border: 0;
					resize: none;
				}

				p {
					color: #ccc;
					text-align: right;
				}
			}

			.divider {
				height: 0;
				background: none;
				border-bottom: dashed px(1) #f1f1f1;
			}

			.inp {
				display: block;
				width: 100%;
				height: px(50);
				line-height: px(50);
				text-align: right;
				font-size: px(16);
				color: #3d3c43;
				border: 0;

				.input-placeholder {
					color: #a0a0a4;
					font-weight: inherit;
				}
			}

			.avatar {
				position: relative;
				width: px(36);
				height: px(36);

				.img {
					display: block;
					width: px(36);
					height: px(36);
					border-radius: px(2);
				}

				.del {
					position: absolute;
					top: 0;
					right: 0;
					width: px(18);
					height: px(18);
					background: url("./assets/icon-2.png") center no-repeat;
					background-size: contain;
					transform: translate(50%, -50%);
					border-radius: 50%;
				}

				&.add {
					position: relative;
					width: px(26);
					height: px(26);
					background: url("./assets/icon-1.png") center no-repeat;
					background-size: contain;
					overflow: hidden;

					.file {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						cursor: pointer;
					}
				}
			}
		}
	}

	.tags {
		display: flex;
		flex-wrap: wrap;

		.tag {
			display: flex;
			align-items: center;
			margin-right: px(15);
			margin-bottom: px(12);
			padding: 0 px(10);
			height: px(24);
			line-height: px(24);
			background: rgba(61, 60, 67, 0.1);
			border-radius: px(12);
			font-size: px(12);
			border: solid px(1) #ebebec;

			.name {
				max-width: px(77);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.icon-del {
				margin-right: px(-10);
				width: px(24);
				height: px(24);
				background: url("../../assets/icon_del.png") center no-repeat;
				background-size: 14px 14px;
			}

			&.add {
				position: relative;
				border-color: $color-primary;
				background: none;
				color: $color-primary;

				.mask {
					position: absolute;
					top: 0;
					left: 0;
					padding: px(2) px(10);
					width: 100%;
					height: 100%;
					pointer-events: none;
					background: #fff;
					z-index: 1;
					border-radius: px(18);
					box-sizing: border-box;
				}

				.icon-add {
					margin-right: px(3);
					width: px(18);
					height: px(18);
					background: url("../../assets/icon_add.png") center no-repeat;
					background-size: contain;
				}

				.inp {
					display: block;
					height: px(18);
					line-height: px(18);
					border: 0;
					background: none;
					font-size: px(12);
					width: px(85);
					box-sizing: border-box;
				}
			}
		}
	}
}

.van-grid-item .van-icon-clear {
	position: absolute;
	top: 0;
	right: 0;
	color: #fe8d36;
}

.van-grid-item .addImg {
	width: px(90);
	height: px(90);
	text-align: center;

	i {
		margin-top: px(25);
		font-size: px(40);
	}

	input {
		display: none;
	}
}
</style>
<style lang="scss">
.friendCirclEdit .van-grid-item__content {
	position: relative;
	padding: px(5);

	.van-image {
		width: px(90);
		height: px(90);
	}
}
</style>
